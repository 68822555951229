<template>
  <div>
    <div class="page-header">
      <img class="bg" src="@/assets/page-header-bg.png" alt="" />
      <span class="text">领用申请</span>
    </div>

    <a-form
      :form="form"
      @submit="handleSubmit"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 16 }"
      :colon="false"
    >
      <a-row>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="领用人">
            <a-select
              v-decorator="[
                'consumerId',
                {
                  rules: [
                    {
                      required: true,
                      message: '请选择',
                    },
                  ],
                },
              ]"
            >
              <a-select-option
                v-for="item in userList"
                :key="item.id"
                :value="item.id"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="领用时间">
            <a-date-picker
              style="width: 100%"
              placeholder
              v-decorator="[
                'consumeTime',
                {
                  rules: [{ required: true, message: '请选择' }],
                },
              ]"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item
            label="领用原因"
            :label-col="{ span: 3 }"
            :wrapper-col="{ span: 20 }"
          >
            <a-textarea
              :auto-size="{ minRows: 3 }"
              v-decorator="[
                'reason',
                {
                  rules: [{ required: true, message: '请输入' }],
                },
              ]"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item
            label="备注"
            :label-col="{ span: 3 }"
            :wrapper-col="{ span: 20 }"
          >
            <a-textarea :auto-size="{ minRows: 3 }" v-decorator="['remark']" />
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item
            label="附件"
            :label-col="{ span: 3 }"
            :wrapper-col="{ span: 20 }"
          >
            <div style="margin-top: -4px">
              <Upload :list="fileList" @change="(list) => (fileList = list)" />
            </div>
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item
            label="领用清单"
            :label-col="{ span: 3 }"
            :wrapper-col="{ span: 20 }"
          >
            <Goods :selected="selected" />
          </a-form-item>
        </a-col>
      </a-row>

      <div class="center">
        <a-space>
          <a-button @click="cancel">关闭</a-button>
          <a-button type="primary" html-type="submit" :loading="loading"
            >提交</a-button
          >
        </a-space>
      </div>
    </a-form>
  </div>
</template>

<script>
import { add } from "../api/use";
import Goods from "./components/goods.vue";
import { mapState } from "vuex";
export default {
  components: {
    Goods,
  },

  data() {
    return {
      form: this.$form.createForm(this),
      selected: [],
      fileList: [],

      loading: false,
    };
  },

  computed: {
    ...mapState("userSelector", ["userList"]),
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true;
          add({
            ...values,
            consumer: this.userList.find(
              (item) => item.id === values.consumerId
            ).name,
            consumeTime: values.consumeTime.format("YYYY-MM-DD"),
            materialList: this.selected.map((item) => {
              return {
                mid: item.id,
                code: item.code,
                name: item.name,
                brand: item.brand,
                specification: item.specification,
                unit: item.unit,
                price: item.price,
              };
            }),
            attachments: this.fileList.map((item) => item.id).join(","),
          })
            .then(() => {
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },

    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>


<style lang="less" scoped>
.page-header {
  position: relative;
  margin-bottom: 1vh;
  .bg {
    width: 240px;
    display: block;
  }

  .text {
    position: absolute;
    top: 10%;
    left: 0;
    bottom: 0;
  }
}
</style>